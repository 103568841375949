import React from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  LinearProgress,
  TextareaAutosize,
  TextField,
} from '@mui/material';
import { ls } from '../../i18n/translations';
import SteielButton from '../ui/SteielButton';
import ErrorMessage from '../ErrorMessage';
import {
  capitalize,
  catchErrors,
  isModuleInCommErr,
  log,
  logErr,
  minMaxCap,
  parseUnitOfMeasure,
} from '../../helpers/utils';
import { Body1, Body2 } from '../ui/Typography';
import { useMQ } from '../../hook/useMQ';
import { colors } from '../../theme';
import { useSetState, useWindowSize } from 'react-use';
import Center from '../ui/Center';
import Flex from '../ui/Flex';
import { assets, CustomSvgIcon } from '../../helpers/assets';
import SteielDialog from '../ui/SteielDialog';
import {
  MachineType,
  MachineVersion,
  Module,
  UnitOfMeasure,
} from '../../graphql/codegen/graphql-react';
import { ModuleSettingType, ParametersType } from '../../graphql/dataTypes';
import SteielCheckbox from '../ui/SteielCheckBox';
import SteielSelect from '../ui/SteielSelect';
import SteielNumericInput from '../ui/SteielNumericInput';
import GaugeChart from '../charts/GaugeChart';
import SteielRadioButton from '../ui/SteielRadioButton';
import ProgressPercChart from '../charts/ProgressPercChart';
import sdkClient from '../../graphql/sdkClient';
import validator from 'validator';
import { measurementsColors, measurementsColorsDarkMode } from '../DeviceInterface';
import { getGlobalModuleSetting } from '../../helpers/globalModuleSettings';
import { useDarkMode } from '../../hook/useDarkMode';

interface DeviceMeasurementsFormProps {
  title: string;
  moduleSetting: ModuleSettingType;
  index: number;
  module: Module | null;
  onSettingsSaved?: () => void;
}

const DeviceMeasurementsForm: React.FC<DeviceMeasurementsFormProps> = ({
  title,
  moduleSetting,
  index,
  module,
  onSettingsSaved,
}) => {
  const [errorFromServer, setErrorFromServer] = React.useState('');
  const { isMobile, isMobileOrTablet } = useMQ();
  const { isDarkMode } = useDarkMode();
  const parameters = module?.Parameter?.parameters as ParametersType;
  const globalModuleSetting = getGlobalModuleSetting(module!);
  const minScale = globalModuleSetting?.defaultValues[index]?.scaleStart ?? -9999;
  const maxScale = globalModuleSetting?.defaultValues[index]?.scaleEnd ?? 9999;
  let minAlarm = 0;
  let maxAlarm = 0;
  let minAcceptableLimit = moduleSetting?.minAcceptableLimit || 0;
  let maxAcceptableLimit = moduleSetting?.maxAcceptableLimit || 0;
  let minWarningLimit = moduleSetting?.minWarningLimit || 0;
  let maxWarningLimit = moduleSetting?.maxWarningLimit || 0;
  let setpoint = 0;
  if (module && module.type === MachineType.Cycleau) {
    if (index === 0) {
      minAlarm = parameters?.minPHAlarm || 0;
      minAcceptableLimit = minAlarm;
      maxAlarm = parameters?.maxPHAlarm || 0;
      maxAcceptableLimit = maxAlarm;
      setpoint = parameters?.setpointPH || 0;
    }
    if (index === 1) {
      minAlarm = parameters?.minFreeChlorine || 0;
      minAcceptableLimit = minAlarm;
      maxAlarm = parameters?.maxFreeChlorine || 0;
      maxAcceptableLimit = maxAlarm;
      setpoint = parameters?.setpointCl2 || 0;
    }
  }
  if (module && (module.type === MachineType.Ew2Pool || module.type === MachineType.Nova2Pool)) {
    if (index === 0) {
      minAlarm = parameters?.minLimitM1 || 0;
      minAcceptableLimit = minAlarm;
      maxAlarm = parameters?.maxLimitM1 || 0;
      maxAcceptableLimit = maxAlarm;
      if (module.type === MachineType.Ew2Pool) {
        switch (parameters?.checkSetpointM1) {
          case 0: {
            setpoint = parameters?.setpointM1Pfm || 0;
            break;
          }
          case 1: {
            setpoint = parameters?.setpointM1OnOff || 0;
            break;
          }
          case 2: {
            setpoint = parameters?.setpointM1Pwm || 0;
            break;
          }
          default: {
            break;
          }
        }
      } else {
        // Nova2Pool
        switch (parameters?.checkSetpointM1) {
          case 1: {
            setpoint = parameters?.setpointM1OnOff || 0;
            break;
          }
          case 3: {
            setpoint = parameters?.setpointM1Pfm || 0;
            break;
          }
          default: {
            break;
          }
        }
      }
    }
    if (index === 1) {
      minAlarm = parameters?.minLimitM2 || 0;
      minAcceptableLimit = minAlarm;
      maxAlarm = parameters?.maxLimitM2 || 0;
      maxAcceptableLimit = maxAlarm;
      if (module.type === MachineType.Ew2Pool) {
        switch (parameters?.checkSetpointM2) {
          case 0: {
            setpoint = parameters?.setpointM2Pfm || 0;
            break;
          }
          case 1: {
            setpoint = parameters?.setpointM2OnOff || 0;
            break;
          }
          case 2: {
            setpoint = parameters?.setpointM2Pwm || 0;
            break;
          }
          default: {
            break;
          }
        }
      } else {
        // Nova2Pool
        switch (parameters?.checkSetpointM2) {
          case 1: {
            setpoint = parameters?.setpointM2OnOff || 0;
            break;
          }
          case 3: {
            setpoint = parameters?.setpointM2Pfm || 0;
            break;
          }
          default: {
            break;
          }
        }
      }
    }
  }
  // TODO for other devices type (STEIEL-54 for now is only for cycleau)
  const [state, setState] = useSetState<{
    open: boolean;
    unitOfMeasure: string;
    enabled: boolean;
    name: string;
    setpoint: string;
    scaleStart: string;
    scaleEnd: string;
    minAlarm: string;
    maxAlarm: string;
    minAcceptableLimit: string;
    maxAcceptableLimit: string;
    minWarningLimit: string;
    maxWarningLimit: string;
    widgetChart: number;
    saving: boolean;
    showSuccessSaveDialog: boolean;
    decimal: string;
    showSaveConfirmDialog: boolean;
  }>({
    open: false,
    unitOfMeasure: parseUnitOfMeasure(moduleSetting?.unitOfMeasure || ''),
    enabled: moduleSetting?.enabled || false,
    name: moduleSetting?.name || '',
    setpoint: String(setpoint),
    scaleStart: String(moduleSetting?.scaleStart || 0),
    scaleEnd: String(moduleSetting?.scaleEnd ?? 100),
    minAlarm: String(minAlarm),
    maxAlarm: String(maxAlarm),
    minAcceptableLimit: String(minAcceptableLimit),
    maxAcceptableLimit: String(maxAcceptableLimit),
    minWarningLimit: String(minWarningLimit),
    maxWarningLimit: String(maxWarningLimit),
    widgetChart: Number(moduleSetting?.widgetChart || 0),
    saving: false,
    showSuccessSaveDialog: false,
    decimal: String(moduleSetting?.decimal ?? 2),
    showSaveConfirmDialog: false,
  });
  const { width: windowWidth } = useWindowSize();
  const scaleStartVal = validator.isNumeric(state.scaleStart)
    ? parseFloat(state.scaleStart)
    : -9999;
  const scaleEndVal = validator.isNumeric(state.scaleEnd) ? parseFloat(state.scaleEnd) : 9999;
  const disableApplyBtn = !module?.online || isModuleInCommErr(module);

  if (!module) {
    return null;
  }

  const isUomSelectable =
    (module.type === MachineType.Mc14Evo &&
      module.modelVersion === MachineVersion.Mc14evo_4CanaliTc &&
      index <= 3) ||
    (module.type === MachineType.NovaNsp &&
      module.modelVersion === MachineVersion.Nsp162PHRx &&
      index === 0);

  const selectableUoms =
    module.type === MachineType.NovaNsp && module.modelVersion === MachineVersion.Nsp162PHRx
      ? [parseUnitOfMeasure(UnitOfMeasure.Ph), parseUnitOfMeasure(UnitOfMeasure.MV)]
      : [
          parseUnitOfMeasure(UnitOfMeasure.Ph),
          parseUnitOfMeasure(UnitOfMeasure.C),
          parseUnitOfMeasure(UnitOfMeasure.PpmCl2),
          parseUnitOfMeasure(UnitOfMeasure.PpmClt),
          parseUnitOfMeasure(UnitOfMeasure.PpmBr2),
          parseUnitOfMeasure(UnitOfMeasure.PpmClc),
          parseUnitOfMeasure(UnitOfMeasure.Ppm),
          parseUnitOfMeasure(UnitOfMeasure.MV),
          parseUnitOfMeasure(UnitOfMeasure.MS),
          parseUnitOfMeasure(UnitOfMeasure.Perc),
          parseUnitOfMeasure(UnitOfMeasure.Cc),
          parseUnitOfMeasure(UnitOfMeasure.Ftu),
          parseUnitOfMeasure(UnitOfMeasure.M3h),
          parseUnitOfMeasure(UnitOfMeasure.MA),
          parseUnitOfMeasure(UnitOfMeasure.MicronS),
          parseUnitOfMeasure(UnitOfMeasure.Min),
          parseUnitOfMeasure(UnitOfMeasure.Ml),
          parseUnitOfMeasure(UnitOfMeasure.Ntu),
          parseUnitOfMeasure(UnitOfMeasure.Sec),
          parseUnitOfMeasure(UnitOfMeasure.CMim),
          parseUnitOfMeasure(UnitOfMeasure.Imp),
        ];

  const Separator: React.ReactNode = isMobileOrTablet ? null : (
    <Box className='separator' width='100%' />
  );

  const handleSaveSettings = async () => {
    try {
      log('saving module settings', state);
      setErrorFromServer('');
      setState({ saving: true });
      if (
        isNaN(parseFloat(state.maxAcceptableLimit)) ||
        isNaN(parseFloat(state.maxAlarm)) ||
        isNaN(parseFloat(state.minAcceptableLimit)) ||
        (state.widgetChart === 3 &&
          (isNaN(parseFloat(state.minWarningLimit)) || isNaN(parseFloat(state.maxWarningLimit)))) ||
        isNaN(parseFloat(state.minAlarm)) ||
        isNaN(parseFloat(state.scaleEnd)) ||
        isNaN(parseFloat(state.scaleStart)) ||
        isNaN(parseFloat(state.setpoint))
      ) {
        throw new Error(ls.badInputData);
      }
      const decimalVal = Number(parseInt(state.decimal).toFixed(0));
      const res = await sdkClient().updateModuleSettings({
        input: {
          moduleId: module.id || 'n/a',
          settingsIndex: index,
          settings: {
            decimal: decimalVal,
            enabled: state.enabled,
            maxAcceptableLimit: Number(parseFloat(state.maxAcceptableLimit).toFixed(decimalVal)),
            maxAlarm: Number(parseFloat(state.maxAlarm).toFixed(decimalVal)),
            minAcceptableLimit: Number(parseFloat(state.minAcceptableLimit).toFixed(decimalVal)),
            minWarningLimit: Number(parseFloat(state.minWarningLimit).toFixed(decimalVal)),
            maxWarningLimit: Number(parseFloat(state.maxWarningLimit).toFixed(decimalVal)),
            minAlarm: Number(parseFloat(state.minAlarm).toFixed(decimalVal)),
            name: state.name,
            scaleEnd: parseInt(state.scaleEnd),
            scaleStart: parseInt(state.scaleStart),
            setpoint: Number(parseFloat(state.setpoint).toFixed(decimalVal)),
            unitOfMeasure: state.unitOfMeasure,
            widgetChart: state.widgetChart,
          } as ModuleSettingType,
        },
      });
      if (res.updateModuleSettings?.id !== module.id) {
        throw new Error(ls.sww);
      }
      setState({ showSuccessSaveDialog: true });
      onSettingsSaved?.();
    } catch (error) {
      logErr('error on saving module settings', error);
      catchErrors(error, setErrorFromServer);
    } finally {
      setState({ saving: false });
    }
  };

  return (
    <Box>
      <Box mx={isMobile ? 2 : 0}>
        <Box
          bgcolor={isDarkMode ? colors.darkBackground2 : 'white'}
          borderRadius={1}
          pt={2}
          sx={{ boxShadow: 1 }}
        >
          <Flex
            style={{ cursor: 'pointer' }}
            pb={1.5}
            onClick={() => {
              setState({ open: !state.open });
            }}
          >
            <Flex>
              <Box ml={4}>
                <Body1 size='1.1rem' weight={600}>
                  {title}
                </Body1>
              </Box>
              {moduleSetting?.unitOfMeasure ? (
                <Box ml={2}>
                  <Center
                    bgcolor={
                      isDarkMode ? measurementsColorsDarkMode[index] : measurementsColors[index]
                    }
                    py={0.7}
                    px={2}
                    mx={0.5}
                    my={0.5}
                    borderRadius={4}
                  >
                    <Body2
                      color='white'
                      size={'0.8rem'}
                      weight={600}
                      mt={0.2}
                      minWidth={30}
                      textAlign='center'
                    >
                      {moduleSetting.unitOfMeasure}
                    </Body2>
                  </Center>
                </Box>
              ) : null}
            </Flex>
            <Box mr={4}>
              <IconButton
                onClick={() => {
                  setState({ open: !state.open });
                }}
                style={{
                  rotate: state.open ? '180deg' : '0deg',
                }}
              >
                <CustomSvgIcon src={assets.interfaceDetail} onClick={() => {}} />
              </IconButton>
            </Box>
          </Flex>
          {state.open ? (
            <Box pb={3}>
              <Flex px={3} py={1}>
                <Box>
                  <SteielCheckbox
                    label={ls.enable.toUpperCase()}
                    checked={state.enabled}
                    onChangeValue={(v) => {
                      setState({ enabled: v });
                    }}
                  />
                </Box>
              </Flex>
              {Separator}
              <Flex
                px={3}
                py={1}
                flexDirection={isMobileOrTablet ? 'column' : 'row'}
                alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
              >
                <Box mt={0.5}>
                  <Body2 color={isDarkMode ? colors.lightGrey : colors.blue} weight={600}>
                    {`${capitalize(ls.name)}:`}
                  </Body2>
                </Box>
                <Box mt={isMobileOrTablet ? 0.5 : 0} width={isMobileOrTablet ? '100%' : undefined}>
                  <TextField
                    size='small'
                    value={state.name}
                    fullWidth={isMobileOrTablet}
                    style={{ width: isMobileOrTablet ? undefined : 190 }}
                    inputProps={{ style: { fontSize: '1.1rem' } }}
                    onChange={(e) => {
                      setState({ name: e.target.value });
                    }}
                  />
                </Box>
              </Flex>
              {isUomSelectable ? (
                <>
                  {Separator}
                  <Flex
                    px={3}
                    py={1}
                    flexDirection={isMobileOrTablet ? 'column' : 'row'}
                    alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                  >
                    <Box mt={0.5}>
                      <Body2 color={isDarkMode ? colors.lightGrey : colors.blue} weight={600}>
                        {`${capitalize(ls.unitOfMeasure)}:`}
                      </Body2>
                    </Box>
                    <Box mt={isMobileOrTablet ? 0.5 : 0} width={isMobileOrTablet ? '100%' : 193}>
                      <SteielSelect
                        allowedValues={selectableUoms}
                        onChange={(value) => {
                          setState({ unitOfMeasure: value as UnitOfMeasure });
                        }}
                        initialVal={state.unitOfMeasure}
                      />
                    </Box>
                  </Flex>
                </>
              ) : null}
              {(module.type === MachineType.Cycleau && (index === 0 || index === 1)) ||
              ((module.type === MachineType.Ew2Pool || module.type === MachineType.Nova2Pool) &&
                (index === 0 || index === 1)) ? (
                <>
                  {Separator}
                  <Flex
                    px={3}
                    py={1}
                    flexDirection={isMobileOrTablet ? 'column' : 'row'}
                    alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                  >
                    <Box mt={0.5}>
                      <Body2 color={isDarkMode ? colors.lightGrey : colors.blue} weight={600}>
                        {`${capitalize(ls.setpoint)}:`}
                      </Body2>
                    </Box>
                    <Box mt={isMobileOrTablet ? 0.5 : 0} width={190}>
                      <SteielNumericInput
                        min={scaleStartVal}
                        max={scaleEndVal}
                        onMinusClick={() => {
                          if (!isNaN(parseFloat(state.setpoint))) {
                            setState({
                              setpoint: String(
                                Number(
                                  minMaxCap(
                                    scaleStartVal,
                                    scaleEndVal,
                                    parseFloat(state.setpoint) - 0.1,
                                  ).toFixed(1),
                                ),
                              ),
                            });
                          }
                        }}
                        onPlusClick={() => {
                          if (!isNaN(parseFloat(state.setpoint))) {
                            setState({
                              setpoint: String(
                                Number(
                                  minMaxCap(
                                    scaleStartVal,
                                    scaleEndVal,
                                    parseFloat(state.setpoint) + 0.1,
                                  ).toFixed(1),
                                ),
                              ),
                            });
                          }
                        }}
                        onValueChange={(v) => {
                          setState({ setpoint: v });
                        }}
                        value={String(state.setpoint)}
                      />
                    </Box>
                  </Flex>
                </>
              ) : null}
              {Separator}
              <Flex
                px={3}
                py={1}
                flexDirection={isMobileOrTablet ? 'column' : 'row'}
                alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
              >
                <Flex
                  flex={1}
                  flexDirection={isMobileOrTablet ? 'column' : 'row'}
                  alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                >
                  <Box mt={isMobileOrTablet ? 0 : 0.5}>
                    <Body2 color={isDarkMode ? colors.lightGrey : colors.blue} weight={600}>
                      {`${capitalize(ls.scaleStart)}:`}
                    </Body2>
                  </Box>
                  <Box mt={isMobileOrTablet ? 0.5 : 0} width={190}>
                    <SteielNumericInput
                      min={minScale}
                      max={maxScale}
                      onMinusClick={() => {
                        if (!isNaN(parseFloat(state.scaleStart))) {
                          setState({
                            scaleStart: String(
                              Number(
                                minMaxCap(
                                  minScale,
                                  maxScale,
                                  parseFloat(state.scaleStart) - 1,
                                ).toFixed(0),
                              ),
                            ),
                          });
                        }
                      }}
                      onPlusClick={() => {
                        if (!isNaN(parseFloat(state.scaleStart))) {
                          setState({
                            scaleStart: String(
                              Number(
                                minMaxCap(
                                  minScale,
                                  maxScale,
                                  parseFloat(state.scaleStart) + 1,
                                ).toFixed(0),
                              ),
                            ),
                          });
                        }
                      }}
                      onValueChange={(v) => {
                        setState({ scaleStart: v });
                      }}
                      value={String(state.scaleStart)}
                    />
                  </Box>
                </Flex>
                <Flex
                  flex={1}
                  ml={isMobileOrTablet ? 0 : 8}
                  mt={isMobileOrTablet ? 1.5 : 0}
                  flexDirection={isMobileOrTablet ? 'column' : 'row'}
                  alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                >
                  <Box mt={0.5}>
                    <Body2 color={isDarkMode ? colors.lightGrey : colors.blue} weight={600}>
                      {`${capitalize(ls.bottomScale)}:`}
                    </Body2>
                  </Box>
                  <Box mt={isMobileOrTablet ? 0.5 : 0} width={190}>
                    <SteielNumericInput
                      min={minScale}
                      max={maxScale}
                      onMinusClick={() => {
                        if (!isNaN(parseFloat(state.scaleEnd))) {
                          setState({
                            scaleEnd: String(
                              Number(
                                minMaxCap(
                                  minScale,
                                  maxScale,
                                  parseFloat(state.scaleEnd) - 1,
                                ).toFixed(0),
                              ),
                            ),
                          });
                        }
                      }}
                      onPlusClick={() => {
                        if (!isNaN(parseFloat(state.scaleEnd))) {
                          setState({
                            scaleEnd: String(
                              Number(
                                minMaxCap(
                                  minScale,
                                  maxScale,
                                  parseFloat(state.scaleEnd) + 1,
                                ).toFixed(0),
                              ),
                            ),
                          });
                        }
                      }}
                      onValueChange={(v) => {
                        setState({ scaleEnd: v });
                      }}
                      value={String(state.scaleEnd)}
                    />
                  </Box>
                </Flex>
              </Flex>
              {/* {module.type === MachineType.Cycleau && (index === 0 || index === 1) ? (
                <>
                  {Separator}
                  <Flex
                    px={3}
                    py={1}
                    flexDirection={isMobileOrTablet ? 'column' : 'row'}
                    alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                  >
                    <Flex
                      flex={1}
                      flexDirection={isMobileOrTablet ? 'column' : 'row'}
                      alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                    >
                      <Box mt={0.5}>
                        <Body2 color={colors.blue} weight={600}>
                          {`${capitalize(ls.minAlarm)}:`}
                        </Body2>
                      </Box>
                      <Box mt={isMobileOrTablet ? 0.5 : 0} width={190}>
                        <SteielNumericInput
                          min={scaleStartVal}
                          max={scaleEndVal}
                          onMinusClick={() => {
                            if (!isNaN(parseFloat(state.minAlarm))) {
                              setState({
                                minAlarm: String(
                                  Number(
                                    minMaxCap(
                                      scaleStartVal,
                                      scaleEndVal,
                                      parseFloat(state.minAlarm) - 0.1,
                                    ).toFixed(1),
                                  ),
                                ),
                              });
                            }
                          }}
                          onPlusClick={() => {
                            if (!isNaN(parseFloat(state.minAlarm))) {
                              setState({
                                minAlarm: String(
                                  Number(
                                    minMaxCap(
                                      scaleStartVal,
                                      scaleEndVal,
                                      parseFloat(state.minAlarm) + 0.1,
                                    ).toFixed(1),
                                  ),
                                ),
                              });
                            }
                          }}
                          onValueChange={(v) => {
                            setState({ minAlarm: v });
                          }}
                          value={String(state.minAlarm)}
                        />
                      </Box>
                    </Flex>
                    <Flex
                      flex={1}
                      ml={isMobileOrTablet ? 0 : 8}
                      mt={isMobileOrTablet ? 1.5 : 0}
                      flexDirection={isMobileOrTablet ? 'column' : 'row'}
                      alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                    >
                      <Box mt={0.5}>
                        <Body2 color={colors.blue} weight={600}>
                          {`${capitalize(ls.maxAlarm)}:`}
                        </Body2>
                      </Box>
                      <Box mt={isMobileOrTablet ? 0.5 : 0} width={190}>
                        <SteielNumericInput
                          min={scaleStartVal}
                          max={scaleEndVal}
                          onMinusClick={() => {
                            if (!isNaN(parseFloat(state.maxAlarm))) {
                              setState({
                                maxAlarm: String(
                                  Number(
                                    minMaxCap(
                                      scaleStartVal,
                                      scaleEndVal,
                                      parseFloat(state.maxAlarm) - 0.1,
                                    ).toFixed(1),
                                  ),
                                ),
                              });
                            }
                          }}
                          onPlusClick={() => {
                            if (!isNaN(parseFloat(state.maxAlarm))) {
                              setState({
                                maxAlarm: String(
                                  Number(
                                    minMaxCap(
                                      scaleStartVal,
                                      scaleEndVal,
                                      parseFloat(state.maxAlarm) + 0.1,
                                    ).toFixed(1),
                                  ),
                                ),
                              });
                            }
                          }}
                          onValueChange={(v) => {
                            setState({ maxAlarm: v });
                          }}
                          value={String(state.maxAlarm)}
                        />
                      </Box>
                    </Flex>
                  </Flex>
                </>
              ) : null} */}
              {Separator}
              <Flex
                px={3}
                py={1}
                flexDirection={isMobileOrTablet ? 'column' : 'row'}
                alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
              >
                <Flex
                  flex={1}
                  flexDirection={isMobileOrTablet ? 'column' : 'row'}
                  alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                >
                  <Box mt={0.5}>
                    <Body2 color={isDarkMode ? colors.lightGrey : colors.blue} weight={600}>
                      {`${capitalize(ls.minAcceptableLimit)}:`}
                    </Body2>
                  </Box>
                  <Box mt={isMobileOrTablet ? 0.5 : 0} width={190}>
                    <SteielNumericInput
                      min={scaleStartVal}
                      max={scaleEndVal}
                      onMinusClick={() => {
                        if (!isNaN(parseFloat(state.minAcceptableLimit))) {
                          setState({
                            minAcceptableLimit: String(
                              Number(
                                minMaxCap(
                                  scaleStartVal,
                                  scaleEndVal,
                                  parseFloat(state.minAcceptableLimit) - 0.1,
                                ).toFixed(1),
                              ),
                            ),
                          });
                        }
                      }}
                      onPlusClick={() => {
                        if (!isNaN(parseFloat(state.minAcceptableLimit))) {
                          setState({
                            minAcceptableLimit: String(
                              Number(
                                minMaxCap(
                                  scaleStartVal,
                                  scaleEndVal,
                                  parseFloat(state.minAcceptableLimit) + 0.1,
                                ).toFixed(1),
                              ),
                            ),
                          });
                        }
                      }}
                      onValueChange={(v) => {
                        setState({ minAcceptableLimit: v });
                      }}
                      value={String(state.minAcceptableLimit)}
                    />
                  </Box>
                </Flex>
                <Flex
                  flex={1}
                  ml={isMobileOrTablet ? 0 : 8}
                  mt={isMobileOrTablet ? 1.5 : 0}
                  flexDirection={isMobileOrTablet ? 'column' : 'row'}
                  alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                >
                  <Box mt={0.5}>
                    <Body2 color={isDarkMode ? colors.lightGrey : colors.blue} weight={600}>
                      {`${capitalize(ls.maxAcceptableLimit)}:`}
                    </Body2>
                  </Box>
                  <Box mt={isMobileOrTablet ? 0.5 : 0} width={190}>
                    <SteielNumericInput
                      min={scaleStartVal}
                      max={scaleEndVal}
                      onMinusClick={() => {
                        if (!isNaN(parseFloat(state.maxAcceptableLimit))) {
                          setState({
                            maxAcceptableLimit: String(
                              Number(
                                minMaxCap(
                                  scaleStartVal,
                                  scaleEndVal,
                                  parseFloat(state.maxAcceptableLimit) - 0.1,
                                ).toFixed(1),
                              ),
                            ),
                          });
                        }
                      }}
                      onPlusClick={() => {
                        if (!isNaN(parseFloat(state.maxAcceptableLimit))) {
                          setState({
                            maxAcceptableLimit: String(
                              Number(
                                minMaxCap(
                                  scaleStartVal,
                                  scaleEndVal,
                                  parseFloat(state.maxAcceptableLimit) + 0.1,
                                ).toFixed(1),
                              ),
                            ),
                          });
                        }
                      }}
                      onValueChange={(v) => {
                        setState({ maxAcceptableLimit: v });
                      }}
                      value={String(state.maxAcceptableLimit)}
                    />
                  </Box>
                </Flex>
              </Flex>
              {state.widgetChart === 3 ? (
                <>
                  {Separator}
                  <Flex
                    px={3}
                    py={1}
                    flexDirection={isMobileOrTablet ? 'column' : 'row'}
                    alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                  >
                    <Flex
                      flex={1}
                      flexDirection={isMobileOrTablet ? 'column' : 'row'}
                      alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                    >
                      <Box mt={0.5}>
                        <Body2 color={isDarkMode ? colors.lightGrey : colors.blue} weight={600}>
                          {`${capitalize(ls.minWarningLimit)}:`}
                        </Body2>
                      </Box>
                      <Box mt={isMobileOrTablet ? 0.5 : 0} width={190}>
                        <SteielNumericInput
                          min={scaleStartVal}
                          max={scaleEndVal}
                          onMinusClick={() => {
                            if (!isNaN(parseFloat(state.minWarningLimit))) {
                              setState({
                                minWarningLimit: String(
                                  Number(
                                    minMaxCap(
                                      scaleStartVal,
                                      scaleEndVal,
                                      parseFloat(state.minWarningLimit) - 0.1,
                                    ).toFixed(1),
                                  ),
                                ),
                              });
                            }
                          }}
                          onPlusClick={() => {
                            if (!isNaN(parseFloat(state.minWarningLimit))) {
                              setState({
                                minWarningLimit: String(
                                  Number(
                                    minMaxCap(
                                      scaleStartVal,
                                      scaleEndVal,
                                      parseFloat(state.minWarningLimit) + 0.1,
                                    ).toFixed(1),
                                  ),
                                ),
                              });
                            }
                          }}
                          onValueChange={(v) => {
                            setState({ minWarningLimit: v });
                          }}
                          value={String(state.minWarningLimit)}
                          readOnly={module.type === MachineType.Cycleau}
                        />
                      </Box>
                    </Flex>
                    <Flex
                      flex={1}
                      ml={isMobileOrTablet ? 0 : 8}
                      mt={isMobileOrTablet ? 1.5 : 0}
                      flexDirection={isMobileOrTablet ? 'column' : 'row'}
                      alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                    >
                      <Box mt={0.5}>
                        <Body2 color={isDarkMode ? colors.lightGrey : colors.blue} weight={600}>
                          {`${capitalize(ls.maxWarningLimit)}:`}
                        </Body2>
                      </Box>
                      <Box mt={isMobileOrTablet ? 0.5 : 0} width={190}>
                        <SteielNumericInput
                          min={scaleStartVal}
                          max={scaleEndVal}
                          onMinusClick={() => {
                            if (!isNaN(parseFloat(state.maxWarningLimit))) {
                              setState({
                                maxWarningLimit: String(
                                  Number(
                                    minMaxCap(
                                      scaleStartVal,
                                      scaleEndVal,
                                      parseFloat(state.maxWarningLimit) - 0.1,
                                    ).toFixed(1),
                                  ),
                                ),
                              });
                            }
                          }}
                          onPlusClick={() => {
                            if (!isNaN(parseFloat(state.maxWarningLimit))) {
                              setState({
                                maxWarningLimit: String(
                                  Number(
                                    minMaxCap(
                                      scaleStartVal,
                                      scaleEndVal,
                                      parseFloat(state.maxWarningLimit) + 0.1,
                                    ).toFixed(1),
                                  ),
                                ),
                              });
                            }
                          }}
                          onValueChange={(v) => {
                            setState({ maxWarningLimit: v });
                          }}
                          value={String(state.maxWarningLimit)}
                          readOnly={module.type === MachineType.Cycleau}
                        />
                      </Box>
                    </Flex>
                  </Flex>
                </>
              ) : null}
              {Separator}
              <Flex
                px={3}
                py={1}
                flexDirection={isMobileOrTablet ? 'column' : 'row'}
                alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
              >
                <Box mt={0.5}>
                  <Body2 color={isDarkMode ? colors.lightGrey : colors.blue} weight={600}>
                    {`${capitalize(ls.decimals)}:`}
                  </Body2>
                </Box>
                <Box mt={isMobileOrTablet ? 0.5 : 0} width={190}>
                  <SteielNumericInput
                    min={0}
                    max={5}
                    onMinusClick={() => {
                      if (!isNaN(parseInt(state.decimal))) {
                        setState({
                          decimal: String(
                            Number(minMaxCap(0, 5, parseFloat(state.decimal) - 1).toFixed(0)),
                          ),
                        });
                      }
                    }}
                    onPlusClick={() => {
                      if (!isNaN(parseInt(state.decimal))) {
                        setState({
                          decimal: String(
                            Number(minMaxCap(0, 5, parseFloat(state.decimal) + 1).toFixed(0)),
                          ),
                        });
                      }
                    }}
                    onValueChange={(v) => {
                      setState({ decimal: v });
                    }}
                    value={String(state.decimal)}
                  />
                </Box>
              </Flex>
              {Separator}
              <Box px={3} py={1}>
                <Box mt={0.5}>
                  <Body2 color={isDarkMode ? colors.lightGrey : colors.blue} weight={600}>
                    {`${capitalize(ls.selectWidget)}:`}
                  </Body2>
                </Box>
                <Flex
                  justifyContent='space-around'
                  flexDirection={isMobileOrTablet ? 'column' : 'row'}
                  alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                >
                  <Center flexDirection='column' mt={2} width='100%'>
                    <Box
                      onClick={() => {
                        setState({ widgetChart: 3 });
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <GaugeChart
                        colors={['#ea3a4a', '#ffba23', '#77c745', '#ffba23', '#ea3a4a']}
                        arcsLength={[0.2, 0.3, 0.4, 0.2, 0.4]}
                        readonly
                        percent={0.56}
                        animate={false}
                        w={210}
                        h={120}
                      />
                    </Box>
                    <Box mt={-4}>
                      <SteielRadioButton
                        checked={state.widgetChart === 3}
                        label=''
                        onClick={() => {
                          setState({ widgetChart: 3 });
                        }}
                      />
                    </Box>
                  </Center>
                  <Center flexDirection='column' mt={2} width='100%'>
                    <Box
                      onClick={() => {
                        setState({ widgetChart: 0 });
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <GaugeChart
                        colors={['#ea3a4a', '#77c745', '#ea3a4a']}
                        arcsLength={[0.2, 0.4, 0.4]}
                        readonly
                        percent={0.42}
                        animate={false}
                        w={210}
                        h={120}
                      />
                    </Box>
                    <Box mt={-4}>
                      <SteielRadioButton
                        checked={state.widgetChart === 0}
                        label=''
                        onClick={() => {
                          setState({ widgetChart: 0 });
                        }}
                      />
                    </Box>
                  </Center>
                  <Box width='100%'>
                    <Center flexDirection='column' mt={2} width='100%'>
                      <Box
                        onClick={() => {
                          setState({ widgetChart: 1 });
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <GaugeChart readonly percent={0.65} animate={false} w={210} h={120} />
                      </Box>
                      <Box mt={-4}>
                        <SteielRadioButton
                          checked={state.widgetChart === 1}
                          label=''
                          onClick={() => {
                            setState({ widgetChart: 1 });
                          }}
                        />
                      </Box>
                    </Center>
                  </Box>
                  <Box width='100%'>
                    <Center flexDirection='column' mt={2}>
                      <Box
                        onClick={() => {
                          setState({ widgetChart: 2 });
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <ProgressPercChart
                          max={10}
                          maxAcceptableValue={0.8}
                          min={0}
                          minAcceptableValue={0}
                          width={isMobileOrTablet ? windowWidth * 0.75 : undefined}
                        />
                      </Box>
                      <Box mt={0}>
                        <SteielRadioButton
                          checked={state.widgetChart === 2}
                          label=''
                          onClick={() => {
                            setState({ widgetChart: 2 });
                          }}
                        />
                      </Box>
                    </Center>
                  </Box>
                </Flex>
              </Box>
              {state.saving && (
                <Box my={2}>
                  <LinearProgress />
                </Box>
              )}
              <ErrorMessage
                containerStyle={{
                  my: 2,
                }}
                message={errorFromServer}
              />
              <Flex
                justifyContent={isMobileOrTablet ? 'center' : 'flex-end'}
                mt={2}
                mr={isMobileOrTablet ? undefined : 3}
                flexDirection={isMobileOrTablet ? 'column' : 'row'}
              >
                {disableApplyBtn ? (
                  <ErrorMessage
                    containerStyle={{
                      mx: 2,
                    }}
                    message={capitalize(ls.canNotSaveOfflineOrErrModule)}
                  />
                ) : null}
                <SteielButton
                  text={capitalize(ls.apply)}
                  disabled={state.saving || disableApplyBtn}
                  style={{
                    backgroundColor: disableApplyBtn ? colors.lightGrey : colors.green,
                    borderColor: disableApplyBtn ? colors.lightGrey : colors.green,
                    width: 150,
                  }}
                  // onClick={() => {
                  //   setState({ showSaveConfirmDialog: true });
                  // }}
                  onClick={handleSaveSettings}
                />
              </Flex>
            </Box>
          ) : null}
        </Box>
      </Box>
      <SteielDialog
        title={capitalize(ls.attention)}
        content={capitalize(ls.saveChangesQuestion)}
        onClose={() => {
          setState({ showSaveConfirmDialog: false });
        }}
        onOkActionClick={async () => {
          setState({ showSaveConfirmDialog: false });
          await handleSaveSettings();
        }}
        open={state.showSaveConfirmDialog}
      />
      <SteielDialog
        title={capitalize(ls.changesCompleted, false)}
        content={capitalize(ls.settingsSavedSuccessfully)}
        onClose={() => {
          setState({ showSuccessSaveDialog: false });
        }}
        onOkActionClick={() => {
          setState({ showSuccessSaveDialog: false });
        }}
        open={state.showSuccessSaveDialog}
        okLabel={capitalize(ls.ok)}
      />
    </Box>
  );
};

export default DeviceMeasurementsForm;
